.prices {
    &__content {
        margin-bottom: 60px;
    }

    &_pane {
        margin-bottom: 30px;
    }

    &_content {
        padding: var(--gap-s);
        box-sizing: border-box;
        border: 2px solid var(--gold-transparent);
        border-radius: 8px;
        margin: 10px;
        max-width: 700px;

        @media (max-width: 412px) {
            width: 100%;
        }


        @media (max-width: 820px) {
            display: block;
        }
    }

    &_title {
        font-size: 20px;
        font-weight: normal;
        color: var(--gold-dark);
    }

    &_row {
        display: flex;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.8);
        padding: 6px 8px;
        margin-bottom: 8px;

        @media (max-width: 412px) {
            display: block;
        }


        @media (max-width: 820px) {
            display: block;
        }
    }

    &_buy {
        margin-left: 20px;

        a {
            font-size: 14px;
            color: var(--gold);
            font-weight: bold;
            text-decoration: none;
            padding: 0 10px;

            &:hover {
                color: var(--gold-dark);
            }
        }
    }

    &_price {
        margin-right: 20px;
    }
}
