.contacts {
    &__map {
        margin-bottom: 60px;
    }

    .leaflet-container {
        width: 100%;
        height: 600px;
    }
}

.leaflet-attribution-flag {
    display: none !important;
}

.leaflet-tooltip-pane {
    font-size: 14px;
}
