:root {
    --violet-dark: #130e1f;
    --violet: #3b2154;
    --gold: #EEE493;
    --gold-transparent: rgba(238, 228, 147, 0.6);
    --gold-dark: #BEA444;
    --pink: #FFEAE5;
    --gap: 20px;
    --gap2x: 40px;
    --gap-s: 10px;
}
