@import '../../vars.css';

.navbar {
    background: var(--violet);

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;

        @media (max-width: 820px) {
            padding-right: 20px;
        }
    }

    &__mobile-menu {
        display: none;
        @media (max-width: 820px) {
            display: block;
        }
    }

    &__burger {
        background: url('./img/icon_menu.png') no-repeat top center transparent;
        border: none;
        cursor: pointer;
        height: 30px;
        overflow: hidden;
        width: 36px;
    }

    &__mlinks {
        position: absolute;
        display: none;

        &_visible {
            display: flex;
            flex-direction: column;
            background: var(--violet);
            border-top: 1px solid #666;
            right: 0;
            top: 100px;
            padding: 16px;
        }

        a {
            text-transform: uppercase;
            margin-bottom: 10px;

            @media (max-width: 412px) {
                padding-right: 20px;
                font-size: 20px !important;
            }
        }
    }

    &__links {
        @media (max-width: 820px) {
            display: none;
        }
    }

    &__logo {
        img {
            width: 80px;
        }
    }

    a {
        font-size: 14px;
        color: var(--gold);
        font-weight: bold;
        text-decoration: none;
        padding: 0 10px;

        &:hover {
            color: var(--gold-dark);
        }

        &.active {
            color: var(--gold-dark);
        }
    }

    &__phone {
        color: var(--pink);
        display: flex;
        justify-items: center;
        align-items: center;

        img {
            margin-right: 5px;
        }

        a {
            color: var(--pink);

            &:hover {
                color: var(--pink);
            }

            &.active {
                color: var(--pink);
            }
        }
    }

    &__book {
        --darker: #333;

        a {
            color: var(--darker);
            text-decoration: none;
            font-weight: bold;
            background: var(--gold);
            padding: 6px 18px;
            border-radius: 3px;

            &:hover {
                color: var(--gold);
                background: var(--gold-dark);
            }

            @media screen and (max-width: 400px) {
                font-size: 20px;
                padding: 10px 30px;
            }
        }

        @media (max-width: 412px) {
            display: none;
        }
    }
}
