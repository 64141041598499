@import 'vars.css';

.App {
  color: var(--pink);
  text-align: left;

  .content {
    max-width: 1080px;
    margin: 0 auto;
  }

  h1 {
    font-size: 28px;
    font-weight: bold;
  }

  h2 {
    color: var(--gold);
    font-size: 24px;
    font-weight: bold;
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
    color: var(--gold-dark);
  }
}
