@import '../../../vars.css';

.contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--gap2x) 0;

    .healcode {
        text-align: center;
        font-size: 16px;
        color:var(--gold-dark) !important;

        &-prospect-promo-text {
            display: none;
            margin-bottom: 10px;
        }

        input {
            &[type="text"] {
                font-size: 16px;
                border: 2px solid var(--gold);
                border-radius: 8px;
                padding: 10px 10px;
                width: 250px;
                background: transparent;
                color:var(--gold-dark);
                margin-bottom: 10px;
            }

            &[type="submit"] {
                color: #fff;
                text-decoration: none;
                background: var(--gold-dark);
                padding: 6px 18px;
                border-radius: 3px;
                border: none;
                height: 40px;
                width: 120px;
                margin-top: 10px;
                font-size: 16px;
                &:hover {
                    color: var(--gold-dark);
                    background: var(--gold);
                }

                @media screen and (max-width: 400px) {
                    font-size: 20px !important;
                    width: 100% !important;
                    height: 3em !important;
                }
            }


        }

        .parsley-errors-list {
            margin-bottom: 10px;
        }
    }
}
