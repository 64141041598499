@import '../../../vars.css';

.whoweare {
    max-width: 500px;
    margin: var(--gap2x) auto;
    text-align: justify;

    h2 {
        color: var(--gold);
        text-align: center;
    }

    &__text {
        @media screen and (max-width: 412px) {
            padding: 0 16px;
        }
    }
}
