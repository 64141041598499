@import '../../vars.css';

.footer {
    background: url("/public/footer.png") no-repeat top center var(--violet);

    @media screen and (max-width: 412px) {
        padding: 0 16px;
    }

    .content {
        display: flex;
        justify-content: space-between;
        justify-items: flex-start;
        padding-top: 40px;

        @media screen and (max-width: 412px) {
            flex-direction: column;
        }
    }

    &__logo {
        img {
            width: 80px;
        }
    }

    &__slogan {
        margin: 10px;
    }

    &__slinks {
        display: flex;
        > div {
            margin-right: 10px;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
    }

    &__sub-title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 20px;
    }

    &__rows {
        > div {
            display: flex;
            margin-bottom: 10px;
        }

        img {
            margin-right: 10px;
        }
    }

    a {
        color: var(--pink);
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
