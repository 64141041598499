@import '../../../vars.css';

.whychoose {
    margin: var(--gap2x) 0;

    h2 {
        text-align: center;
    }

    &__items {
        display: flex;
        justify-content: space-around;
        align-items: stretch;

        @media screen and (max-width: 412px) {
            flex-direction: column;
            padding: 0 16px;
        }
    }

    &__item {
        text-align: center;
        padding:  var(--gap2x);
        box-sizing: border-box;
        border: 2px solid var(--gold-transparent);
        border-radius: 8px;
        margin: 10px;
        width: 33%;

        @media screen and (max-width: 412px) {
            width: 100%;
            margin: 0;
            margin-bottom: 16px;
        }
    }

    &__sub {
        margin: var(--gap) auto;
        text-align: justify;
        width: 500px;
        font-size: 20px;
        font-weight: normal;
        color: var(--gold-dark);

        @media (max-width: 800px) {
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
        }

        @media screen and (max-width: 412px) {
            padding: 0 16px;
            margin: 0;
        }
    }
}
